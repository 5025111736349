import dayjs from "dayjs"
import meterpointStatus from "../../methods/meterpointStatus"
import { formatMixin } from "../../mixins/formatMixin"
import { isSmartMeter as isSmartMeterType } from "@soenergy/frontend-library/src/helpers/meterTypeHelper"

export const namespaced = true

export const getters = {
  accountCreated(state, getters, rootState, rootGetters) {
    const account = rootGetters.account

    return account.createdDttm
  },
  meterpoints(state, getters, rootState, rootGetters) {
    const agreementIdsNames = getLatestAgreementIds(rootGetters.agreements)
    const meters = rootGetters.meterpoints
    const supplyStatus = rootGetters.supply_status

    return Object.keys(meters)
      .filter((id) => agreementIdsNames.filter((item) => item.id === id))
      .reduce((obj, key) => {
        const meter = meters[key]
        const agreementData = agreementIdsNames[key]
        let isSmartMeter = false

        // check for smart meter
        if (meter.meters && meter.meters.length) {
          const allEndPointAreSmart = meter.meters.every((meter) =>
            isSmartMeterType(meter.meterType)
          )
          // auto reads enabled, and all meters are smart
          isSmartMeter =
            meter.meterPointServiceType === "DCC" && allEndPointAreSmart
        }

        let currentMeterReadings = rootGetters.readings[meter.id]
        let lastReadingDate = ""
        if (currentMeterReadings.length) {
          lastReadingDate =
            currentMeterReadings[currentMeterReadings.length - 1].receivedDttm
        }

        let store = {
          meterpointId: meter.id,
          id: meter.identifier,
          type: meter.type,
          supplyStartDate: meter.supplyStartDate,
          supplyStatus: meter.supplyStatus,
          supplyEventType: supplyStatus[meter.id].eventType,
          hasFirstRead: !!currentMeterReadings.length,
          lastReadingDate,
          meterPointServiceType: meter.meterPointServiceType,
          meters: meter.meters,
          fuelName: meter.type === "MPAN" ? "electricity" : "gas",
          tariffName: agreementData.name,
          isSmartMeter: isSmartMeter,
        }

        if (meterpointStatus.isSwitchCancelled(store)) {
          store["cancelled"] = supplyStatus[meter.id].createdDttm
        }
        const daysFromEvent = dayjs().diff(
          dayjs(supplyStatus[meter.id].createdDttm),
          "day"
        )

        if (daysFromEvent > 14) {
          return obj
        }
        obj.push(store)
        return obj
      }, [])
  },
  inFirstReadingPeriod(state, getters) {
    const currentDate = dayjs()
    const meterValues = getters.meterpoints
    return meterValues.some((meter) => {
      const supplyStartDate = meter.supplyStartDate
      const start = dayjs(supplyStartDate)
      const end = dayjs(supplyStartDate).add(5, "day")
      return currentDate.isAfter(start) && currentDate.isBefore(end)
    })
  },
  isSwitchComplete(state, getters) {
    let isSwitchComplete = false
    const meterValues = getters.meterpoints
    if (meterValues && meterValues.length) {
      isSwitchComplete = meterValues.every((meter) => {
        const currentDate = dayjs()
        const stopBannerDisplay = dayjs(meter.supplyStartDate).add(5, "day")
        const eventTypeCompleted = ["RegistrationCompleted"].includes(
          meter.supplyEventType
        )
        const supplyStatusConfirmed = ["Registered"].includes(
          meter.supplyStatus
        )
        return (
          eventTypeCompleted &&
          supplyStatusConfirmed &&
          currentDate.isBefore(stopBannerDisplay)
        )
      })
    }
    return isSwitchComplete
  },
  isSwitchStopped(state, getters) {
    return !!getters.meterpoints?.every((meter) =>
      meterpointStatus.isSwitchStopped(meter)
    )
  },
  nextPaymentDate(state, getters, rootState, rootGetters) {
    const currentPaymentSchedule =
      rootGetters["payments/currentPaymentSchedule"]
    return currentPaymentSchedule
      ? dayjs(currentPaymentSchedule.nextPaymentDt).format("D MMM YYYY")
      : ""
  },
  paymentAmount(state, getters, rootState, rootGetters) {
    const currentPaymentSchedule =
      rootGetters["payments/currentPaymentSchedule"]
    return currentPaymentSchedule
      ? formatMixin.methods.absoluteGBP(currentPaymentSchedule.amount)
      : "Stopped"
  },
  isSwitchInProgress(state, getters) {
    let isSwitchInProgress = false
    const meterValues = getters.meterpoints
    if (meterValues && meterValues.length) {
      isSwitchInProgress = meterValues.some((meter) =>
        meterpointStatus.isSwitchingIn(meter)
      )
    }
    return isSwitchInProgress
  },
  isEveryMeterSwitchingIn(state, getters, rootState, rootGetters) {
    return checkMeterSwitchInStatus(
      rootGetters["meters/allCurrent"],
      rootGetters.supply_status
    )
  },
  isEveryElectricityMeterSwitchingIn(state, getters, rootState, rootGetters) {
    return checkMeterSwitchInStatus(
      rootGetters["meters/allCurrentElectricity"],
      rootGetters.supply_status
    )
  },
  isEveryGasMeterSwitchingIn(state, getters, rootState, rootGetters) {
    return checkMeterSwitchInStatus(
      rootGetters["meters/allCurrentGas"],
      rootGetters.supply_status
    )
  },
  isSwitchCancellationInProgress(state, getters) {
    let isSwitchCancellationInProgress = false
    const meterValues = getters.meterpoints
    if (meterValues && meterValues.length) {
      isSwitchCancellationInProgress = meterValues.every((meter) => {
        return ["LossNotified"].includes(meter.supplyStatus)
      })
    }
    return isSwitchCancellationInProgress
  },
  differentSwitchStartDates(state, getters) {
    let hasSameStartDates = true
    const meterValues = getters.meterpoints
    if (meterValues && meterValues.length && meterValues.length > 1) {
      hasSameStartDates = meterValues.every((meter) => {
        return (
          meter.supplyStartDate?.toString() ===
          meterValues[0].supplyStartDate?.toString()
        )
      })
    }
    return !hasSameStartDates
  },
  hasPendingCancellation(state, getters, rootState) {
    return rootState.user.cancellations.length > 0
  },
  hasFirstReads(state, getters) {
    return getters.meterpoints.every((meter) => meter.hasFirstRead)
  },
  hasLegacyMeterMissingFirstReads(state, getters) {
    return Object.values(getters.meterpoints).some(
      (meterpoint) => !meterpoint.isSmartMeter && !meterpoint.hasFirstRead
    )
  },
  hasFirstReadsForGas(state, getters) {
    return getters.meterpoints
      .filter((meter) => meter.fuelName === "gas")
      .every((meter) => meter.hasFirstRead)
  },
  hasFirstReadsForElectricity(state, getters) {
    return getters.meterpoints
      .filter((meter) => meter.fuelName === "electricity")
      .every((meter) => meter.hasFirstRead)
  },
  isSwitchingAway(state, getters, rootState, rootGetters) {
    const meters = Object.values(rootGetters.supply_status)
    if (
      meters &&
      meters.length > 0 &&
      (rootGetters["agreements/hasActiveAgreements"] ||
        rootGetters["agreements/anyAgreementIsOutOfContract"])
    ) {
      const lossStatus = meters.some((meter) => {
        const eventLossInitiated = [
          "LossObjectionRaised",
          "LossInitiated",
          "LossConfirmed",
        ].includes(meter.eventType)
        const eventLossCompleted = ["LossCompleted"].includes(meter.eventType)
        const statusLossNotified = ["LossNotified", "LossObjected"].includes(
          meter.supplyStatus
        )
        const statusLossConfirmed = ["LossConfirmed"].includes(
          meter.supplyStatus
        )

        const isSwitching =
          eventLossInitiated &&
          !eventLossCompleted &&
          statusLossNotified &&
          !statusLossConfirmed

        return isSwitching
      })
      return lossStatus
    }
    return false
  },
}

const getLatestAgreementIds = (agreements) => {
  let latestAgreements = []

  // Loops through agreements and pushed the meterpoint ID (not the MPAN) to an array
  agreements.forEach((agreement) => {
    if (
      agreement.products &&
      agreement.products[0] &&
      agreement.products[0].assets &&
      agreement.products[0].assets[0]
    ) {
      const assetId = String(agreement.products[0].assets[0].id)
      // Avoid duplication by overriding the keys
      latestAgreements[assetId] = {
        id: String(agreement.products[0].assets[0].id),
        name: agreement.products[0].displayName.split("-")[0],
      }
    }
  })

  return latestAgreements
}

const checkMeterSwitchInStatus = (meters, supplyStatus) => {
  let isEveryMeterSwitchingIn = false
  const meterValues = Object.values(meters).flat()

  if (meterValues && meterValues.length) {
    isEveryMeterSwitchingIn = meterValues.every((meter) =>
      meterpointStatus.isSwitchingIn({
        ...meter,
        supplyEventType: supplyStatus[meter.endpointID].eventType,
      })
    )
  }
  return isEveryMeterSwitchingIn
}
