import Vue from "vue"
import Vuex from "vuex"
import router from "../router"
import ErrorReporter from "@soenergy/frontend-library/src/services/ErrorReporter"
import AuthenticationService from "@soenergy/frontend-library/src/services/AuthenticationService"
import PersistentStoreService from "@soenergy/frontend-library/src/services/PersistentStoreService"
import UserStatusService from "@soenergy/frontend-library/src/services/UserStatusService"
import WHDEligibilityService from "@soenergy/frontend-library/src/services/WHDEligibilityService"
import Rails from "../services/RailsService.js"
import { handleCriticalError } from "@/methods/handleErrors"
import { showAccount } from "@/methods/storeHelper"
import waitForJuniferCacheRefreshToFinish from "@/methods/waitForJuniferCacheRefreshToFinish"
import dayjs from "dayjs"
import { postcodeValidation } from "@soenergy/frontend-library/src/helpers/postcodeValidationHelper"
import isBetween from "dayjs/plugin/isBetween"
import minMax from "dayjs/plugin/minMax"
import payments from "./modules/payments"
import * as meters from "./modules/meters.js"
import * as bills from "./modules/bills.js"
import * as paymentPlans from "./modules/paymentPlans.js"
import * as chooseAccount from "./modules/chooseAccount.js"
import * as home from "./modules/home.js"
import * as tariffs from "./modules/tariffs.js"
import * as agreements from "./modules/agreements.js"
import * as user from "./modules/user.js"
import * as switchIn from "./modules/switchIn.js"
import * as renew from "./modules/renew"
import * as help from "./modules/help.js"
import * as modals from "./modules/modals.js"
import * as gengame from "./modules/gengame.js"
import * as usage from "./modules/usage.js"
import * as psr from "./modules/psr.js"
import * as readLogin from "./modules/readLogin.js"
import * as smbp from "./modules/smbp.js"
import * as cotMoveOut from "./modules/cotMoveOut.js"
import * as cotMoveWithUs from "./modules/cotMoveWithUs.js"
import * as pendingReadingSubmissions from "./modules/pendingReadingSubmissions"
import tariffProductHelper from "../methods/tariffProductHelper"
dayjs.extend(isBetween)
dayjs.extend(minMax)
Vue.use(Vuex)

export const state = {
  projectVersion: process.env.PROJECT_VERSION,
  junifer: false,
  currentAccount: null,
  loading: false,
  juniferDataRefreshPending: false,
  juniferDataRefreshTime: null,
  loginRedirectPath: null,
  fetchedDataError: false,
  isUserAdmin: false,
  isProspectSwitchJourneyEnabled: null,
  cotMoveOutTypeformUrl: null,
  isCotMoveOutJourneyEnabled: null,
  isCotMoveWithUsJourneyEnabled: null,
  isReadLoginEnabled: null,
  isExternalFormReadLoginEnabled: null,
  isDirectDebitChangesEnabled: null,
  isDebtPaymentPlanEnabled: null,
  isPaymentScheduleQueuingEnabled: null,
  isSmbpPopupEnabled: null,
  juniferCurrentAccountId: null,
}

export const modules = {
  meters,
  bills,
  payments,
  paymentPlans,
  chooseAccount,
  home,
  tariffs,
  agreements,
  user,
  switchIn,
  renew,
  help,
  modals,
  gengame,
  usage,
  psr,
  readLogin,
  smbp,
  cotMoveOut,
  cotMoveWithUs,
  pendingReadingSubmissions,
}

export const mutations = {
  SET_JUNIFER_DATA(state, junifer) {
    state.junifer = junifer
  },
  SET_CURRENT_ACCOUNT(state, account) {
    state.currentAccount = account
  },
  UPDATE_LOADING(state, isLoading) {
    state.loading = isLoading
  },
  SET_JUNIFER_DATA_REFRESH_PENDING(state, juniferDataRefreshPending) {
    state.juniferDataRefreshPending = juniferDataRefreshPending
  },
  SET_LOGIN_REDIRECT_PATH(state, loginRedirectPath) {
    state.loginRedirectPath = loginRedirectPath
  },
  SET_FETCHED_DATA_ERROR(state, fetchedDataError) {
    state.fetchedDataError = fetchedDataError
  },
  SET_JUNIFER_DATA_REFRESH_TIME(state, juniferDataRefreshTime) {
    state.juniferDataRefreshTime = juniferDataRefreshTime
  },
  SET_IS_USER_ADMIN(state, isUserAdmin) {
    state.isUserAdmin = isUserAdmin
  },
  SET_PROSPECT_SWITCH_JOURNEY_STATUS(state, prospectSwitchJourney) {
    state.isProspectSwitchJourneyEnabled = prospectSwitchJourney.includes(
      process.env.TARGET_ENV
    )
  },
  SET_COT_MOVE_OUT_TYPEFORM_URL(state, cotMoveOutTypeformUrl) {
    state.cotMoveOutTypeformUrl = cotMoveOutTypeformUrl
  },
  SET_MOVE_WITH_US_JOURNEY_STATUS(state, isMoveWithUsJourneyEnabled) {
    state.isCotMoveWithUsJourneyEnabled = isMoveWithUsJourneyEnabled.includes(
      process.env.TARGET_ENV
    )
  },
  SET_COT_MOVE_OUT_JOURNEY_ENABLED(state, isCotMoveOutJourneyEnabled) {
    state.isCotMoveOutJourneyEnabled = isCotMoveOutJourneyEnabled.includes(
      process.env.TARGET_ENV
    )
  },
  SET_PROMOTE_RENEWAL_STATUS(state, promoteRenewal) {
    state.isPromoteRenewalEnabled = promoteRenewal.includes(
      process.env.TARGET_ENV
    )
  },
  SET_EXTERNAL_READ_LOGIN_ENABLED(state, environmentsSupportingReadLogin) {
    state.isExternalFormReadLoginEnabled =
      environmentsSupportingReadLogin.includes(process.env.TARGET_ENV)
  },
  SET_READ_LOGIN_ENABLED(state, environmentsSupportingReadLogin) {
    state.isReadLoginEnabled = environmentsSupportingReadLogin.includes(
      process.env.TARGET_ENV
    )
  },
  SET_DIRECT_DEBIT_CHANGES_ENABLED(state, isDirectDebitChangesEnabled) {
    state.isDirectDebitChangesEnabled = isDirectDebitChangesEnabled.includes(
      process.env.TARGET_ENV
    )
  },
  SET_DEBT_PAYMENT_PLAN_ENABLED(state, isDebtPaymentPlanEnabled) {
    state.isDebtPaymentPlanEnabled = isDebtPaymentPlanEnabled.includes(
      process.env.TARGET_ENV
    )
  },
  SET_PAYMENT_SCHEDULE_QUEUING_ENABLED(state, isPaymentScheduleQueuingEnabled) {
    state.isPaymentScheduleQueuingEnabled = isPaymentScheduleQueuingEnabled
  },
  SET_SMBP_POPUP_ENABLED(state, isSmbpPopupEnbled) {
    state.isSmbpPopupEnabled = isSmbpPopupEnbled.includes(
      process.env.TARGET_ENV
    )
  },
  SET_JUNIFER_CURRENT_ACCOUNT_ID(state, juniferCurrentAccountId) {
    state.juniferCurrentAccountId = juniferCurrentAccountId
  },
}

export const actions = {
  resetStateOnSwitch({ commit }) {
    commit("payments/RESET_STATE")
    commit("paymentPlans/RESET_STATE")
    commit("bills/RESET_STATE")
    commit("renew/RESET_STATE")
    commit("tariffs/RESET_STATE")
    commit("home/RESET_STATE")
    commit("cotMoveOut/RESET_STATE")
    commit("cotMoveWithUs/RESET_STATE")
  },
  login(_, credentials) {
    return AuthenticationService.login(credentials)
  },
  async afterLogin({ state, commit, getters, dispatch, rootGetters }) {
    commit("UPDATE_LOADING", true)
    const data = await dispatch("fetchWebsiteData")
    const currentAccountId = data?.current_account_id

    /** 
      After the Nova BE cutover, the current_account_id from /api/v1/user.json will
      represent the NOVA billing ID, so we will use junifer_current_account_id for calls
      to SMBP BE or Kotlin BE. If junifer_current_account_id doesn't exist in the user
      payload, we default back to current_account_id so that this will work even before the cutover.

      In myaccount, we are only calling SMBP BE with an account ID to fetch the campaign status
      after login, so we to use the new junifer_current_account_id if it exists, otherwise
      fallback to current_account_id.
    */

    const juniferCurrentAccountId =
      data?.junifer_current_account_id || currentAccountId
    let juniferDataFetched = false
    const getUserStatus = async () => {
      const userStatus = await UserStatusService.getUserStatusWithToken()
      if (userStatus && userStatus.admin) {
        commit("SET_IS_USER_ADMIN", true)
        await dispatch("logout")
        throw new Error("adminLogin")
      }
    }
    if (!currentAccountId) {
      await getUserStatus()
      await dispatch("fetchJuniferData")
      await dispatch("ensureActiveAccountSelected")
      juniferDataFetched = true
    }

    commit("SET_JUNIFER_CURRENT_ACCOUNT_ID", juniferCurrentAccountId)
    await Promise.all([
      dispatch("fetchJuniferDataAndRefreshIfNeeded", {
        skipFetch: juniferDataFetched,
      }),
      getUserStatus(),
    ]).then(async () => {
      await dispatch("ensureActiveAccountSelected")

      const toFetch = [
        WHDEligibilityService.getWHDRegionEligibility(currentAccountId),
        dispatch("smbp/fetchCampaignStatus", juniferCurrentAccountId),
      ]

      if (state.isDebtPaymentPlanEnabled) {
        toFetch.push(
          dispatch("paymentPlans/fetchEligibility", currentAccountId)
        )
        toFetch.push(
          dispatch("paymentPlans/fetchCurrentPaymentPlan", currentAccountId)
        )
      }

      await Promise.all(toFetch)
      await dispatch("agreements/fetchRenewalDays")
      await dispatch("smbp/sendSmartMeterEligibilityTracking")
      await dispatch("cotMoveOut/ensureCotMoveOutRecordFetched")
      await dispatch("cotMoveWithUs/ensureCotMoveWithUsRecordFetched")
      commit("UPDATE_LOADING", false)
      if (getters.hasFetchedDataError) {
        router.push("login-error").catch(() => {})
      }
    })
  },
  setCurrentAccount({ commit, dispatch }, accountId) {
    return Rails.setCurrentAccount(accountId)
      .then(() => {
        commit("SET_CURRENT_ACCOUNT", accountId)
        dispatch("bills/fetchBills", accountId)
      })
      .catch((error) => handleCriticalError(error, "setCurrentAccount API"))
  },
  fetchJuniferData({ commit }) {
    return Rails.fetchJunifer()
      .then((response) => {
        if (response && response.data) {
          commit("SET_JUNIFER_DATA", response.data)
          if (response.data.customer) {
            commit("user/SET_CUSTOMER_DATA", response.data.customer)
          }
        } else {
          commit("SET_FETCHED_DATA_ERROR", true)
          ErrorReporter.report(new Error("fetchJuniferData has failed"), {
            serverResponse: response,
          })
        }
      })
      .catch((error) => handleCriticalError(error, "fetchJuniferData API"))
  },
  async refreshJuniferData(
    { commit, dispatch, getters },
    { isRetry, refreshInProgress } = {}
  ) {
    commit("SET_JUNIFER_DATA_REFRESH_PENDING", true)
    try {
      if (!refreshInProgress) {
        Rails.refreshJuniferCacheForAccount(getters.currentAccount).catch(
          (error) => {
            const errorMessage =
              error.response && error.response.data && error.response.data.error
            const unexpectedError =
              errorMessage !== "Account was synced less than 10 minutes ago"
            if (unexpectedError) {
              throw error
            }
          }
        )
      }

      const { status } = await waitForJuniferCacheRefreshToFinish()
      await dispatch("fetchJuniferData")
      if (status === "timeout") {
        dispatch("showRefreshError")
        waitForJuniferCacheRefreshToFinish({ maxTimeout: null }).then(() => {
          dispatch("fetchJuniferData")
        })
      } else if (!getters.isJuniferDataCurrent && !isRetry) {
        dispatch("refreshJuniferData", { isRetry: true })
      }
    } finally {
      commit("SET_JUNIFER_DATA_REFRESH_PENDING", false)
      commit("UPDATE_LOADING", false)
    }
  },
  async fetchJuniferDataAndRefreshIfNeeded(
    { getters, dispatch, state },
    { skipFetch } = {}
  ) {
    const status = await dispatch(
      "fetchBlobRefreshStatus",
      state.currentAccount
    )

    if (getters.isJuniferDataCurrent) {
      if (!skipFetch) await dispatch("fetchJuniferData")
    } else {
      await dispatch("refreshJuniferData", {
        refreshInProgress: status === "pending",
      })
    }
  },
  async fetchBlobRefreshStatus({ commit, state }) {
    try {
      const response = await Rails.getJuniferAccountCacheRefreshStatus(
        state.currentAccount
      )
      commit("SET_JUNIFER_DATA_REFRESH_TIME", response.data.timestamp)
      return response.data.sync_status
    } catch (error) {
      const updateStatus =
        error &&
        error.response &&
        error.response.data &&
        error.response.data.sync_status
      if (updateStatus) {
        return updateStatus
      } else {
        throw error
      }
    }
  },
  showRefreshError({ commit, dispatch }) {
    commit("SET_JUNIFER_DATA_REFRESH_PENDING", false)
    commit("UPDATE_LOADING", false)
    dispatch("modals/showGenericModal", {
      message: `
        Sorry, an error occurred while loading your account details.
        The data shown might be out of sync.
      `,
    })
    ErrorReporter.report(new Error("Junifer cache refresh timeout"))
  },
  fetchWebsiteData({ commit, dispatch }) {
    return Rails.fetchWebsite()
      .then((response) => {
        commit("SET_CURRENT_ACCOUNT", response.data.current_account_id)
        dispatch("user/setUserData", response.data)
        if (!response.data?.voucher?.url) {
          ErrorReporter.report(new Error("Referral URL missing"), {
            severity: "info",
            voucher: response.data?.voucher,
          })
        }
        return response.data
      })
      .catch((error) => {
        commit("SET_FETCHED_DATA_ERROR", true)
        ErrorReporter.report(error)
      })
  },
  async ensureActiveAccountSelected({ dispatch, getters, rootGetters }) {
    const selectedAccount = getters.currentAccount
    const currentAccounts = rootGetters["chooseAccount/currentAccounts"]
    const accounts = rootGetters["chooseAccount/accounts"]
    if (!selectedAccount && !accounts.length) {
      throw new Error("Junifer blob returned 0 accounts")
    }
    if (
      currentAccounts.length === 1 &&
      selectedAccount !== currentAccounts[0].id
    ) {
      await dispatch("setCurrentAccount", currentAccounts[0].id)
    } else if (selectedAccount === null && currentAccounts.length) {
      await dispatch("setCurrentAccount", currentAccounts[0].id)
    } else if (selectedAccount === null) {
      await dispatch("setCurrentAccount", accounts[0].id)
    }
  },
  async logout({ commit, state }) {
    PersistentStoreService.disablePersistence()
    PersistentStoreService.clearStoredState()
    await AuthenticationService.logout(true)
    UserStatusService.clearUserStatus()

    if (state.isUserAdmin) {
      commit("UPDATE_LOADING", true)
      await AuthenticationService.logout(false)
      window.location.href = `${process.env.VUE_APP_ACCOUNT_SITE_URL}/admin/sign_in`
    } else {
      location.reload()
    }
  },
  async tryLogout({ dispatch }) {
    try {
      await dispatch("logout")
    } catch (error) {
      if (!error.isAxiosError) throw error

      try {
        await dispatch("logout")
      } catch (error) {
        if (!error.isAxiosError) throw error

        dispatch("modals/showGenericModal", {
          message: `
          Looks like there’s a problem with logging out! Please clear your browser cookies.
          `,
        })
      }
    }
  },
  setCommonCmsSettings({ commit }, commonCmsSettings) {
    const isWebsiteBeMaintenanceEnabled =
      commonCmsSettings.websiteBeMaintenance.includes(process.env.TARGET_ENV)
    if (isWebsiteBeMaintenanceEnabled) {
      window.location.href = `${process.env.VUE_APP_WEBSITE_URL}/maintenance`
    }

    commit(
      "SET_PROSPECT_SWITCH_JOURNEY_STATUS",
      commonCmsSettings.prospectSwitchJourney
    )
    commit(
      "SET_MOVE_WITH_US_JOURNEY_STATUS",
      commonCmsSettings.cotMoveWithUsJourneyEnabled
    )
    commit("SET_PROMOTE_RENEWAL_STATUS", commonCmsSettings.promoteRenewal)
    commit(
      "SET_COT_MOVE_OUT_JOURNEY_ENABLED",
      commonCmsSettings.cotMoveOutJourneyEnabled
    )
    commit(
      "SET_COT_MOVE_OUT_TYPEFORM_URL",
      commonCmsSettings.cotMoveOutTypeformUrl
    )
  },
  setAccountCmsSettings({ commit }, accountCmsSettings) {
    commit("SET_READ_LOGIN_ENABLED", accountCmsSettings.isReadLoginEnabled)
    commit(
      "SET_EXTERNAL_READ_LOGIN_ENABLED",
      accountCmsSettings.isExternalFormReadLoginEnabled
    )
    commit(
      "SET_DIRECT_DEBIT_CHANGES_ENABLED",
      accountCmsSettings.isDirectDebitChangesEnabled
    )
    commit(
      "SET_DEBT_PAYMENT_PLAN_ENABLED",
      accountCmsSettings.isDebtPaymentPlanEnabled
    )
    commit(
      "SET_PAYMENT_SCHEDULE_QUEUING_ENABLED",
      accountCmsSettings.isPaymentScheduleQueuingEnabled
    )
    commit("SET_SMBP_POPUP_ENABLED", accountCmsSettings.isSmbpPopupEnabled)
  },
}

export const getters = {
  appVersion: (state) => {
    return state.projectVersion
  },
  ready(state) {
    return (
      !!state.junifer &&
      !!state.user.userDataFetched &&
      !!state.userStatus.userStatus &&
      !!state.whdRegionEligibility
    )
  },
  isLoggedIn() {
    return AuthenticationService.isAuthenticated()
  },
  isJuniferDataCurrent(state) {
    if (!state.juniferDataRefreshTime && !state.junifer.timestamp) {
      return false
    }
    let refreshThreshold = dayjs().subtract(
      process.env.VUE_APP_BLOB_REFRESH_AMOUNT,
      process.env.VUE_APP_BLOB_REFRESH_UNIT
    )
    const dataLastRefreshed = dayjs.max(
      dayjs(state.juniferDataRefreshTime || false),
      dayjs(state.junifer.timestamp || false)
    )
    return dataLastRefreshed.isAfter(refreshThreshold)
  },
  junifer(state) {
    return state.junifer
  },
  currentAccount(state) {
    return state.currentAccount
  },
  account(state) {
    let account = {}

    if (state.junifer && state.currentAccount) {
      let currentAccount = state.junifer.accounts[state.currentAccount]
      let currentAccountData = currentAccount.account

      account = {
        ...currentAccountData,
        showAccount: showAccount(currentAccountData, currentAccount.agreements),
      }
    }
    return account
  },
  getCurrentAccountClosingDate(state) {
    let currentAccount = state.junifer.accounts[state.currentAccount].account

    const acctToDt = currentAccount.toDt
      ? dayjs(currentAccount.toDt, "YYYY-MM-DD")
      : null

    return "closedDttm" in currentAccount // use account closing date if exists
      ? dayjs(currentAccount.closedDttm, "YYYY-MM-DD") // otherwise use accToDate
      : acctToDt
  },
  supply_status(state) {
    return state.junifer.accounts[state.currentAccount].supply_status
  },
  payments(state) {
    return state.junifer.accounts[state.currentAccount].payments
  },
  postcode(state) {
    return state.junifer.accounts[state.currentAccount].postcode
  },
  validatedPostcode(state, getters) {
    if (!getters.account?.supplyAddress?.postcode) return null
    return postcodeValidation(getters.account.supplyAddress.postcode)
  },
  readings(state) {
    return state.junifer.accounts[state.currentAccount].readings
  },
  agreements(state) {
    return state.junifer.accounts[state.currentAccount].agreements
  },
  meterpoints(state) {
    return state.junifer.accounts[state.currentAccount].meterpoints
  },
  transactions(state) {
    return state.junifer.accounts[state.currentAccount].transactions
  },
  estimated_usage(state) {
    return state.junifer.accounts[state.currentAccount].estimated_usage
  },
  payment_methods(state) {
    if (state.junifer.accounts && state.currentAccount) {
      return state.junifer.accounts[state.currentAccount].payment_methods
    } else {
      return []
    }
  },
  payment_periods(state) {
    return state.junifer.accounts[state.currentAccount].payment_periods
  },
  payment_method_dd_ids(state) {
    return state.junifer.accounts[state.currentAccount].payment_method_dd_ids
  },
  productDetails(state) {
    return state.junifer.accounts[state.currentAccount].product_details
  },
  currentAgreements(state, getters) {
    const startOfToday = dayjs().startOf("day").hour(12) // was matching old and new agreement on day of start of new agreement
    const endOfToday = dayjs().endOf("day")

    // Current agreements
    const current = getters.agreements.filter((a) => {
      let hasValidFuelType
      if (a.products[0]) {
        hasValidFuelType = tariffProductHelper.isValidFuelType(
          a.products[0].reference
        )
      }
      return (
        hasValidFuelType &&
        startOfToday.isBetween(
          dayjs(a.fromDt),
          a.toDt ? dayjs(a.toDt) : endOfToday
        ) &&
        !a.cancelled
      )
    })

    const future = getters.agreements.filter(
      (a) => dayjs(a.fromDt).isAfter(endOfToday) && !a.cancelled
    )

    const metersInCurrentAgreements = current
      .map((agreement) => {
        if (agreement.products[0].assets[0]) {
          return agreement.products[0].assets[0].identifier
        }
      })
      .filter(Boolean)

    const newFutureAgreements = future
      .filter((a) => {
        if (a.products[0].assets[0]) {
          return !metersInCurrentAgreements.includes(
            a.products[0].assets[0].identifier
          )
        }
      })
      .filter(Boolean)

    return [...current, ...newFutureAgreements]
  },
  futureAgreements(state, getters) {
    const today = dayjs().endOf("day")
    return getters.agreements.filter(
      (a) => dayjs(a.fromDt).isAfter(today) && !a.cancelled
    )
  },
  billsSinceLastRead(state, getters) {
    const lastRead = dayjs(
      getters["meters/lastReadMinMax"].max,
      "D MMM YYYY"
    ).subtract(1, "day")

    return getters.bills && getters.bills.length
      ? getters.bills.reduce(
          (count, bill) =>
            dayjs(bill.periodTo).isAfter(lastRead) ? ++count : count,
          0
        )
      : 0
  },

  /**
   * Calculates the last bill date based on the transactions.
   * @returns {Dayjs|undefined} - The last bill date or null if no bill transactions found.
   */
  lastBillDate(state, getters) {
    return getters.transactions.reduce((lastTransactionDate, transaction) => {
      if (transaction.type === "Bill" && transaction.acceptedDttm) {
        const date = dayjs(transaction.acceptedDttm)
        if (!lastTransactionDate || date.isAfter(lastTransactionDate)) {
          return date
        }
      }
      return lastTransactionDate
    }, undefined)
  },
  allCurrentElectricityAgreements(state, getters) {
    return getters.currentAgreements.filter((a) =>
      a.products.some((product) =>
        product.internalName.startsWith("Electricity")
      )
    )
  },
  allCurrentGasAgreements(state, getters) {
    return getters.currentAgreements.filter((a) =>
      a.products.some((product) => product.internalName.startsWith("Gas"))
    )
  },
  allCurrentElectricityProducts(state, getters) {
    return getters.allCurrentElectricityAgreements.reduce(
      (products, agreement) => {
        return products.concat(
          agreement.products.map((product) => {
            return {
              ...product,
              fromDt: agreement.fromDt,
            }
          })
        )
      },
      []
    )
  },
  allCurrentGasProducts(state, getters) {
    return getters.allCurrentGasAgreements.reduce((products, agreement) => {
      return products.concat(
        agreement.products.map((product) => {
          return {
            ...product,
            fromDt: agreement.fromDt,
          }
        })
      )
    }, [])
  },
  isUserAdmin(state) {
    return state.userStatus.userStatus.admin
  },
  hasFetchedDataError(state) {
    return state.fetchedDataError
  },
}

export default new Vuex.Store({
  state,
  modules,
  actions,
  mutations,
  getters,
})
