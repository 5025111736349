<template>
  <NotificationBanner
    v-if="showBanner"
    :banner-content="filterBanners"
    :variable-set="variableData"
    :account-id="currentAccount"
    :account-open="account.showAccount"
    :fullscreen-banner-dismissed="fullscreenBannerDismissed"
    @fullscreen-banner-dismissed="setFullscreenBannerDismissed(true)"
  />
</template>

<script>
import { mapGetters, mapState, mapMutations } from "vuex"
import cmsPreviewMixin from "@soenergy/frontend-library/src/mixins/cmsPreviewMixin"
import NotificationBanner from "./NotificationBanner.vue"
import handleBanners from "@/methods/handleBanners.js"
import homeContent from "soenergy-cms-loader!?path=account/home"
import dayjs from "dayjs"
import {
  GET_SWITCH_DATE,
  GET_ENERGY_ON_SUPPLY,
  GET_SMART_METER_APPOINTMENT_DATE,
  GET_SMART_METER_APPOINTMENT_TIME,
  DAYS_TO_RENEW,
  FUEL_MISSING_FIRST_READS,
} from "@/variable_keys/Banners.js"
import { datesMixin } from "../mixins/datesMixin"

export default {
  components: {
    NotificationBanner,
  },
  mixins: [
    cmsPreviewMixin({ story: homeContent, name: "bannerContent" }),
    datesMixin,
  ],
  computed: {
    ...mapGetters({
      ready: "ready",
      isLoggedIn: "isLoggedIn",
      account: "account",
      currentPaymentSchedule: "payments/currentPaymentSchedule",
      agreements: "agreements/agreements",
      anyOutOfContract: "agreements/anyAgreementIsOutOfContract",
      supplyStatus: "supply_status",
      isInFirstReadingPeriod: "switchIn/inFirstReadingPeriod",
      hasFirstReads: "switchIn/hasFirstReads",
      lastReadMinMax: "meters/lastReadMinMax",
      hasLegacyMeter: "meters/hasLegacyMeter",
      isSwitchComplete: "switchIn/isSwitchComplete",
      isSwitchInProgress: "switchIn/isSwitchInProgress",
      hasLegacyMeterMissingFirstReads:
        "switchIn/hasLegacyMeterMissingFirstReads",
      hasFirstReadsForGas: "switchIn/hasFirstReadsForGas",
      hasFirstReadsForElectricity: "switchIn/hasFirstReadsForElectricity",
      hasNonCommunicatingSmartMeter: "meters/hasNonCommunicatingSmartMeter",
      hasSmartMeter: "meters/hasSmartMeter",
      accountNumber: "home/accountNumber",
      supplyStartDates: "home/supplyStartDates",
      hasElectricity: "meters/hasElectricity",
      hasGas: "meters/hasGas",
      hasActiveDirectDebit: "payments/hasActiveDirectDebit",
      hasPendingDirectDebit: "payments/hasPendingDirectDebit",
      hasTooHighDebtToRenewOnFutureTariff:
        "payments/hasTooHighDebtToRenewOnFutureTariff",
      currentAccount: "currentAccount",
      featureData: "help/getFeatureData",
      isWHDRegionEligible: "whdRegionEligibility/isWHDRegionEligible",
      canRenewOnFutureTariff: "agreements/canRenewOnFutureTariff",
      eligibleForRenewal: "agreements/eligibleForRenewal",
      smbpCampaignIsPassive: "smbp/smbpCampaignIsPassive",
      hasNonDDTariff: "agreements/hasNonDDTariff",
      hasCancelledDirectDebit: "payments/hasCancelledDirectDebit",
      isEligibleForPaymentPlan: "paymentPlans/isEligible",
      hasActiveAgreements: "agreements/hasActiveAgreements",
      isEligibleForSmartMeter: "smbp/isEligibleForSmartMeter",
      isSwitchingAway: "switchIn/isSwitchingAway",
    }),
    ...mapState({
      user: (state) => state.user,
      smbpData: (state) => state.userStatus.userStatus.smbpData,
      currentEnergySourceVote: (state) => state.user.currentEnergySourceVote,
      isProspectSwitchJourneyEnabled: (state) =>
        state.isProspectSwitchJourneyEnabled,
      fullscreenBannerDismissed: (state) =>
        state.home.fullscreenBannerDismissed,
      daysToRenew: (state) => state.home.daysToRenew,
      hasAvailableSmbpSlots: (state) => state.smbp.hasAvailableSmbpSlots,
    }),
    showBanner() {
      return (
        this.ready &&
        this.isLoggedIn &&
        this.bannerContent &&
        this.bannerContent.homeBanners &&
        !this.$route.meta.hideAccountBanner
      )
    },
    variableData() {
      // TODO: When refactoring the banners, keep variable data together with banner-specific
      // config, for scalability and to avoid regressions when updating other banners
      return {
        [GET_SWITCH_DATE]: dayjs(this.sortedSupplyStartDates[0]).format(
          "DD-MM-YYYY"
        ),
        [GET_ENERGY_ON_SUPPLY]: this.energyTypesOnSupply,
        [GET_SMART_METER_APPOINTMENT_DATE]:
          this.smbpData && this.smbpData.bookingData
            ? this.formatDate(this.smbpData.bookingData.date)
            : null,
        [GET_SMART_METER_APPOINTMENT_TIME]:
          this.smbpData && this.smbpData.bookingData
            ? this.smbpData.bookingData.startTime.slice(0, 5)
            : null,
        [DAYS_TO_RENEW]: this.daysToRenew,
        [FUEL_MISSING_FIRST_READS]: this.energyTypeMissingFirstReads,
      }
    },
    bannerData() {
      return {
        allBanners: [...this.bannerContent.homeBanners],
        hasSeasonalPaymentPlan:
          !!this.currentPaymentSchedule?.seasonalPaymentFl,
        agreementsArray: Object.values(this.agreements),
        anyOutOfContract: this.anyOutOfContract,
        hasActiveAgreements: this.hasActiveAgreements,
        supplyStatus: this.supplyStatus,
        smbpData: this.smbpData,
        currentEnergySourceVote: this.currentEnergySourceVote,
        hasActiveDirectDebit: this.hasActiveDirectDebit,
        hasPendingDirectDebit: this.hasPendingDirectDebit,
        hasTooHighDebtToRenewOnFutureTariff:
          this.hasTooHighDebtToRenewOnFutureTariff,
        isInFirstReadingPeriod: this.isInFirstReadingPeriod,
        hasLegacyMeter: this.hasLegacyMeter,
        hasSmartMeter: this.hasSmartMeter,
        hasFirstReads: this.hasFirstReads,
        hasLegacyMeterMissingFirstReads: this.hasLegacyMeterMissingFirstReads,
        hasFirstReadsForGas: this.hasFirstReadsForGas,
        hasFirstReadsForElectricity: this.hasFirstReadsForElectricity,
        lastReadMinMax: this.lastReadMinMax,
        dateToday: dayjs().toString(),
        variables: this.variableData,
        isSwitchComplete: this.isSwitchComplete,
        isSwitchInProgress: this.isSwitchInProgress,
        hasNonCommunicatingSmartMeter: this.hasNonCommunicatingSmartMeter,
        user: this.user,
        accountNumber: this.accountNumber,
        accountOpen: this.account.showAccount,
        isProspectSwitchJourneyEnabled: this.isProspectSwitchJourneyEnabled,
        isEligibleForEvCampaign: this.user.evCampaign,
        featureData: this.featureData,
        hasElectricity: this.hasElectricity,
        isWHDRegionEligible: this.isWHDRegionEligible,
        canRenewOnFutureTariff: this.canRenewOnFutureTariff,
        eligibleForRenewal: this.eligibleForRenewal,
        smbpCampaignIsPassive: this.smbpCampaignIsPassive,
        hasAvailableSmbpSlots: this.hasAvailableSmbpSlots,
        hasNonDDTariff: this.hasNonDDTariff,
        hasCancelledDirectDebit: this.hasCancelledDirectDebit,
        isEligibleForPaymentPlan: this.isEligibleForPaymentPlan,
        isEligibleForSmartMeter: this.isEligibleForSmartMeter,
        isSwitchingAway: this.isSwitchingAway,
        marketingOptOutBanner: this.user.showMarketingOptOutBanner,
      }
    },
    filterBanners() {
      return handleBanners(this.bannerData)
    },
    sortedSupplyStartDates() {
      let sortDates = [...this.supplyStartDates].sort((a, b) =>
        dayjs(a).isAfter(dayjs(b)) ? 1 : 1
      )
      return sortDates
    },
    energyTypeMissingFirstReads() {
      if (!this.hasFirstReadsForGas) return "gas"
      if (!this.hasFirstReadsForElectricity) return "electricity"
      return ""
    },
    energyTypesOnSupply() {
      let energyTypesOnSupply
      if (this.hasActiveAgreements && this.hasElectricity && this.hasGas) {
        energyTypesOnSupply = "electricity and gas are "
      } else if (this.hasElectricity && !this.hasGas) {
        energyTypesOnSupply = "electricity is"
      } else if (!this.hasElectricity && this.hasGas) {
        energyTypesOnSupply = "gas is"
      }
      return energyTypesOnSupply
    },
  },
  methods: {
    ...mapMutations({
      setFullscreenBannerDismissed: "home/SET_FULLSCREEN_BANNER_DISMISSED",
    }),
  },
}
</script>
