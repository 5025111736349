import GatewayPortal from "@/services/GatewayPortal"
import SmartMeterService from "../../services/SmartMeterService"
import ErrorReporter from "@soenergy/frontend-library/src/services/ErrorReporter"
import MixpanelService from "@soenergy/frontend-library/src/services/Mixpanel"
import dayjs from "dayjs"
export const namespaced = true

export const state = {
  smbpCampaignStatus: null,
  hasAvailableSmbpSlots: false,
  smbpAvailableSlots: null,
  bookings: null,
  bookingStatus: {
    loading: false,
    error: false,
  },
}

export const mutations = {
  SET_CAMPAIGN_STATUS(state, campaignStatus) {
    state.smbpCampaignStatus = campaignStatus
  },
  SET_AVAILABLE_SLOTS(state, slots) {
    state.hasAvailableSmbpSlots = slots
  },
  SET_SMBP_BOOKING_SLOTS(state, slots) {
    state.smbpAvailableSlots = slots
  },
  SET_BOOKINGS(state, bookingDetails) {
    state.bookings = bookingDetails
  },
}

export const actions = {
  async fetchCampaignStatus({ commit }, accountId) {
    await SmartMeterService.getCampaignStatus(accountId)
      .then((status) => {
        commit("SET_CAMPAIGN_STATUS", status.data.campaign_status)
      })
      .catch((err) => {
        const errorMessage = err?.response?.data?.message
        const isExpectedError =
          typeof errorMessage === "string" &&
          errorMessage?.includes("This account has no campaign status record")
        if (!isExpectedError) ErrorReporter.report(err)
      })
  },
  async fetchAvailableSlots({ commit, rootGetters, getters }) {
    if (getters.isEligibleForSmartMeter && rootGetters.validatedPostcode) {
      await GatewayPortal.getSmbpAvailableSlots(rootGetters.validatedPostcode)
        .then((response) => {
          if (response?.data?.days?.length) {
            const filteredSlots = response.data.days.filter(
              (day) => day.slots.length
            )
            if (filteredSlots.length) {
              commit("SET_AVAILABLE_SLOTS", true)
              commit("SET_SMBP_BOOKING_SLOTS", filteredSlots)
            }
          }
        })
        .catch((err) => {
          if (err.response && err.response.status !== 404) {
            ErrorReporter.report(err)
          }
        })
    }
  },
  sendSmartMeterEligibilityTracking({ state, getters }) {
    const smbpCampaign =
      state.smbpCampaignStatus?.status || "No campaign status"
    const smbpEligibility = getters.isEligibleForSmartMeter
      ? "Eligible"
      : "Not Eligible"
    const smbpAvailableSlots = state.hasAvailableSmbpSlots
      ? "Has Available Slots"
      : "No Available Slots"
    MixpanelService.sendEvent("Smart Meter Tracking", {
      campaignStatus: smbpCampaign,
      smartMeterEligibility: smbpEligibility,
      availableSlots: smbpAvailableSlots,
    })
  },
  async fetchBookings({ commit, rootState }) {
    try {
      const fetchedBookings = await GatewayPortal.getBookings(
        rootState.currentAccount
      )
      commit("SET_BOOKINGS", fetchedBookings.data.bookings)
    } catch (err) {
      ErrorReporter.report(err)
    }
  },
}

export const getters = {
  smbpCampaignIsPassive(state) {
    return state.smbpCampaignStatus?.status === "passive"
  },
  isEligibleForSmartMeter(state, getters, rootState, rootGetters) {
    return (
      (rootGetters["agreements/anyAgreementIsOutOfContract"] ||
        rootGetters["agreements/hasActiveAgreements"]) &&
      rootState.userStatus.userStatus.smbpData &&
      rootState.userStatus.userStatus.smbpData.eligible &&
      !rootState.userStatus.userStatus.smbpData.booked &&
      !rootGetters["switchIn/isSwitchingAway"] &&
      !rootGetters["meters/hasSmartMeter"]
    )
  },
  isSmbpPopupShown(state, getters, rootState, rootGetters) {
    return (
      rootState.isSmbpPopupEnabled &&
      getters.isEligibleForSmartMeter &&
      state.hasAvailableSmbpSlots &&
      rootGetters["meters/allMetersReceivedReadingsToday"]
    )
  },
  latestBooking(state) {
    if (!state.bookings.length) return null
    const filteredBookings = state.bookings.filter(
      (booking) => booking["created-at"]
    )
    if (!filteredBookings.length) return null
    return filteredBookings.reduce((latestBooking, currentBooking) => {
      return dayjs(currentBooking["created-at"]).isAfter(
        dayjs(latestBooking["created-at"])
      )
        ? currentBooking
        : latestBooking
    })
  },
  gasMeter(state, getters, rootState, rootGetters) {
    return Object.values(rootGetters["meters/allCurrentGas"])
      .flat()
      .find((meter) => meter.meterpointIdentifier && meter.meterIdentifier)
  },
  electricMeter(state, getters, rootState, rootGetters) {
    return Object.values(rootGetters["meters/allCurrentElectricity"])
      .flat()
      .find((meter) => meter.meterpointIdentifier && meter.meterIdentifier)
  },
  electricSerialNumber(state, getters) {
    return getters.electricMeter?.meterIdentifier || ""
  },
  gasSerialNumber(state, getters) {
    return getters.gasMeter?.meterIdentifier || ""
  },
  mpan(state, getters) {
    return getters.electricMeter?.meterpointIdentifier || ""
  },
  mprn(state, getters) {
    return getters.gasMeter?.meterpointIdentifier || ""
  },
}
